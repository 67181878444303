
import PriceProps from './PriceProps'
const props = extendValues => class extends PriceProps {};

class Price extends props({}) {



  setNewPrice = (price_setting, target_date, unit, is_holiday, is_holiday_prev_day) => {
    const unit_base = price_setting.unit_step[unit-1] ?  Number(price_setting.unit_step[unit  -1 ]) + Number(price_setting.base_price) : Number(price_setting.base_price)
    
    //曜日
    const week_price = price_setting.week_step.length > target_date.getDay() ? price_setting.week_step[target_date.getDay()] : 0
    
    //祝日＆祝日前
    const holiday_price = is_holiday && price_setting.holiday_step[0] ? price_setting.holiday_step[0] : is_holiday_prev_day && price_setting.holiday_step[1] ? price_setting.holiday_step[1] :0
    
    //高い方を採用
    const add_price_day = week_price >= holiday_price ? week_price : holiday_price
    
    const price_w_day = unit_base + add_price_day 

    return (this.setIn(["new_price"], price_w_day))
    
  }


 // 

}
export default Price


