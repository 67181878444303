import { useState } from 'react';
import PriceAction from './PriceAction.js';
import ResponseData from '../../../action/ResponseData';
import Price from './Price.js';
import { format } from 'date-fns';




/**
type MailJson = { 
  id:string
  from_address:string 
};
**/


function usePriceState() {

  const [error, setError] = useState(null)
  const [created, setCreated] = useState<Date | null>(null)


  const loadHandler = (data:ResponseData) => {
    setCreated(new Date())
  }
  const errorHandler = (data:ResponseData) => {
    setError(data.error)
  }

  const update = (token:string, plan_id:number, id:number, price:number, ) => {
    PriceAction.update(token, plan_id, id, price, loadHandler, errorHandler)
  } 
  const create = (token:string, items:Array<Price>, plan_id:number) => {
    const form_items = items.map( p => {
      if( p.target_date){
        return {"target_date":format( p.target_date, "yyyy-MM-dd"), "price":p.new_price, "unit":p.unit}
      } else{
        return null
      }
      
    })

    PriceAction.create(token, form_items, plan_id, loadHandler, errorHandler)
  } 

  

  return { price_error:error, price_created:created, updatePrice:update, createPrice:create}

}

export default usePriceState