import { Record } from 'immutable'


type PriceSettingProps = {
  base_price:number | null
  unit_step:Array<number>
  week_step:Array<number>
  holiday_step:Array<number>
  updated_at:Date
};
const defaultValues: PriceSettingProps = {
  base_price:null,
  unit_step:[],
  week_step:[],
  holiday_step:[0,0],
  updated_at:new Date()
};




class PriceSetting extends Record(defaultValues){


}
export default PriceSetting



