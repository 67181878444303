import { Record } from 'immutable'

const  PriceProps = Record({
  id:null,
  tmp_id:null,
  target_date:null,
  unit:null,
  price:null,
  new_price:null
});
export default PriceProps;

