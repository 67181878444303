import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import { format } from 'date-fns'

var PlanActions = {

  create(token,accommodation_id, item){
    const form = {
      name:item.name,
      close_date_before:item.close_date_before,
      end_at:format(item.end_at,"yyyy-MM-dd"),
      start_at:format(item.start_at,"yyyy-MM-dd"),
      is_active:item.is_active,
      max_num:item.max_num,
      description:item.description,
      check_in:item.check_in,
      check_out:item.check_out,
      price:item.price,
      ikyu_id:item.ikyu_id,
      payment_method:item.payment_method,
      icon:item.icon,
      notice_mail:item.notice_mail
    }
    
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/plans" , {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        PlanActions.getList(token,accommodation_id)
        AppDispatcher.dispatch({
          actionType: Constants.PLAN_CREATED,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  update(token,accommodation_id,plan_id, params){
    const params_without_prices = params.set("prices",null).set("start_at", params.start_at ? format(params.start_at, "yyyy-MM-dd"): null).set("end_at", params.end_at ? format(params.end_at, "yyyy-MM-dd"): null)
    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/plans/" + plan_id , {
      method: "PATCH",
      headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(params_without_prices)
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error){
        PlanActions.getList(token,accommodation_id)
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    }).catch(function(error) {
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        message:config.get("ERROR_API")
      })
    })
  },

  csvTest(token,plan_id,file){
    var formData = new FormData();
    formData.append('csv', file);
    fetch(config.get("API_PATH") + config.get("API_PLAN") + "/" + plan_id + config.get("API_SCHEDULE") + "/csv/check", {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token
      },
      body: formData
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){

        AppDispatcher.dispatch({
          actionType: Constants.PLAN_PRICES_LOADED,
          list:data.items
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_CSV_TEST")
        })
      }
    })
  },

  csvCreate(token,plan_id,file,accommodation_id){
    var formData = new FormData();
    formData.append('csv', file);
    fetch(config.get("API_PATH") + config.get("API_PLAN") + "/" + plan_id + config.get("API_SCHEDULE") + "/csv", {
      method: "POST",
      headers: {
          'Authorization': 'Bearer ' + token
      },
      body: formData
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        window.location.href = "/m/accommodation/" + accommodation_id + "/plan/" + plan_id + "/price"

      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  getList(token,accommodation_id,start_date = null,end_date = null){

    let params = new URLSearchParams();
    if(start_date){
      params.set('start_date', format(start_date,"yyyy-MM-dd"));
    }
    if(end_date){
      params.set('end_date', format(end_date,"yyyy-MM-dd"));
    }

    //params.set('end_date', format(to, 'yyyy-MM-dd'));

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/plans?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.PLAN_LIST_LOADED,
          list:data.plans
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  getDetail(token,accommodation_id, plan_id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/plans/" + plan_id, {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.PLAN_DETAIL_LOADED,
          item:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })
  },

  delete(token,accommodation_id, plan_id){

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/plans/" + plan_id, {
      method: "DELETE",
      headers: {
          'Authorization': 'Bearer ' + token
      },
    }).then(function(response) {
      if(response.status === 204  ){
        return true
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {

      if(data && !data.error){
        PlanActions.getList(token,accommodation_id)
        AppDispatcher.dispatch({
          actionType: Constants.PLAN_DELETED,
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error,
          message:config.get("ERROR_API")
        })
      }
    })
  },

  getIkyuStatus(token,accommodation_id, plan_id, year, month){

    let params = new URLSearchParams();
    params.set('month', month);
    params.set('year', year);

    fetch(config.get("API_PATH") + config.get("API_ACCOMMODATION") + "/" + accommodation_id + "/plans/" +plan_id + "/ikuyu?" + params.toString(), {
      method: "GET",
      headers: {
          'Authorization': 'Bearer ' + token
      }
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":402}
        }
      }else if(response.status === 401){
        return null
      }else{
        return  {"error":response.status}
      }
    }).then(function(data) {
      if(data && !data.error){
        AppDispatcher.dispatch({
          actionType: Constants.PLAN_GET_IKYU,
          data:data.status
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data.error
        })
      }
    })

  },

  clearIkyu(){
    AppDispatcher.dispatch({
      actionType: Constants.PLAN_CLEAR_IKYU,
    })
  },

  updateCsv(file){
    AppDispatcher.dispatch({
      actionType: Constants.CSV_SELECTED,
      file:file
    })
  },
  updateItem(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.PLAN_UPDATE,
      name:name,
      value:value
    })
  },
  setItem(item){
    AppDispatcher.dispatch({
      actionType: Constants.PLAN_SET,
      item:item
    })
  }

}

export default PlanActions
