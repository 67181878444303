

import React, { useState, useEffect } from 'react'
import usePriceState from '../../../accommodation/plan/price/usePriceState.tsx';

function PlanPriceItem(props:PropsValue) {

  const [price, setPrice] = useState(null)
  const {updatePrice} = usePriceState()
  

  useEffect(() => {
    setPrice(props.price)

  },[props.price]);



  const changePrice = (price, e) => {
    const target = e.target 
    const new_item = price.set("new_price", Number(target.value))
    setPrice(new_item)
    /**if(props.setPrice){
      props.setPrice(new_item)
    }**/
    
  }


  const updateHandler = () => {
    if(price.id){ //更新の場合
      if(price.new_price !== null && Number(price.price) !== Number(price.new_price)){
        updatePrice(props.token, props.plan_id, price.id,Number(price.new_price))
      }
    }
  }

  return(
    <div className="free right">
      {price ?
        props.edit ? 
        <input type="number" className="w-7em" value={price && price.new_price ? price.new_price : price ? price.price : undefined} onBlur={updateHandler} onChange={(e)=> changePrice(price, e)}/> 
          : <div className="no-border">{ price && price.new_price ? price.new_price.toLocaleString() : price.price ? price.price.toLocaleString() : null} 円</div>
      : null}
    </div>
  )
}

export default PlanPriceItem
