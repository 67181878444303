
import { Record } from 'immutable'

type HolidayProps = {
  name:string | null
  target_date:Date | null
};
const defaultValues: HolidayProps = {
  name:null,
  target_date:null,
};

class Holiday extends Record(defaultValues){



 
}
export default Holiday
