

class ActionParams  {
 static readonly PATH =  process.env.REACT_APP_API_PATH ? process.env.REACT_APP_API_PATH  : "http://localhost:9000"
 //static readonly PATH = "http://localhost:9000"
  static readonly API_ID = "356a192b7913b04c54574d18c28d46e6395428ab"
  static readonly API_KEY = "ohk8BephEizea2oo"
 
}


export default ActionParams



