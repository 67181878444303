import { useState } from 'react';
import HolidayAction from './HolidayAction.js';
import ResponseData from '../action/ResponseData.js';
import parseISO from 'date-fns/parseISO';
import Holiday from './Holiday.tsx';

type HoliydayJson = { 
  name:string 
  target_date:string  
};





function useHolidayState() {

  const [error, setError] = useState(null)
  const [holidays, setHolidays] = useState<Array<Holiday>>([])
  const [updated_at, setUpdatedAt] = useState<Date | null>(null)

  const loadHandler = (data:ResponseData) => {
    if(data.data){
      const _list:Array<HoliydayJson> = data.data
      const list:Array<Holiday> = _list.map(holiday => {
        return new Holiday({name:holiday.name, target_date:new Date(parseISO(holiday.target_date))})
      })
      setHolidays(list)
      setUpdatedAt(new Date())
    }
  }

  const errorHandler = (data:ResponseData) => {
    setError(data.error)
  }


  const get = (token:string) => {
    HolidayAction.get(token, loadHandler, errorHandler)
  } 

  

  return { holidays, holidays_updated_at:updated_at, error,  getHolidays:get}

}

export default useHolidayState