import { Record } from 'immutable'


type MailProps = {
  id:string | null,
  from_address:string | null,
};
const defaultValues: MailProps = {
  id:null,
  from_address:null
};




class Mail extends Record(defaultValues){


}
export default Mail



