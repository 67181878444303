import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Plan from "../vo/Plan"
import { isSameDay,isBefore,isAfter } from 'date-fns'
import Price from "../accommodation/plan/price/Price"

const CHANGE_EVENT = "change"
const FORM_CHANGE = "update"
const CREATED = "created"
const DELETED = "deleted"
const CHECK_LOADED = "check_loaded"

var _item = null
var _items = []
var _prices = []
var _selected_csv = null
var _ikyu = []

var setItem = (item) => {
  _item = PlanStore.createObject(item)
}


var setList = (list) => {
  _items = list
}

var setPrices = (list) => {
  _prices = list
}

var setSelectedCSV = (file) => {
  _selected_csv = file
}

var update = (name, value) => {
  _item = _item.set(name, value)
}

var setIkyu = (str) => {
  _ikyu = str.split(",").map(item => {return Number(item)})
}

var clearIkyu = () => {
  _ikyu = []
}


var PlanStore = assign({},EventEmitter.prototype,{

  getItem(){
    return _item
  },


  getList(){
    return _items
  },

  getPrices(){
    return _prices
  },


  getSelectedCsv(){
    return _selected_csv
  },

  getPriceObjectFromList(target_day, unit){

    for(var i = 0; i < _item.prices.length; i++){
      //console.log("coome")
      if(_item.prices[i].unit === unit && isSameDay(new Date(_item.prices[i].target_date),target_day)){

        return new Price(
          {
            id:_item.prices[i].id, 
            target_date:new Date(_item.prices[i].target_date), 
            unit:_item.prices[i].unit, 
            price:_item.prices[i].price
          }
        )
      }
    }
    return null
  },

  getPriceFromList(target_day, unit){

    for(var i = 0; i < _item.prices.length; i++){
      //console.log("coome")
      if(_item.prices[i].unit === unit && isSameDay(new Date(_item.prices[i].target_date),target_day)){
        return _item.prices[i].price
      }
    }
    return null
  },

  getPriceFromPrices(target_day, unit){

    for(var i = 0; i < _prices.length; i++){
      //console.log("coome")
      if(_prices[i].unit === unit && isSameDay(new Date(_prices[i].target_date),target_day)){
        return _prices[i].price
      }
    }
    return null
  },

  getMinDateFromPrices(){
    let min = null;
    for (const s of _prices) {
        if (!min ||isBefore(new Date(s.target_date),min)) min = new Date(s.target_date)
    }
    return min
  },

  getMaxDateFromPrices(){
    let max = null;
    for (const s of _prices) {
        if (!max ||isAfter(new Date(s.target_date),max)) max = new Date(s.target_date)
    }
    return max
  },

  getIkyuStatus(d){
    const target = d -1
    if(_ikyu.length < d) return null

    switch(_ikyu[target]) {
      case 0 : return 0
      case 1 :
      case 2 :
      case 9 :
        return 1
      default: return null
    }
  },


  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },


  createObject(item) {
    const start_date = new Date(item.start_at)
    const end_date = new Date(item.end_at)

    return new Plan({
      id: item.id,
      close_date_before: item.close_date_before,
      name: item.name,
      end_at: end_date,
      start_at:  start_date,
      is_active: item.is_active,
      max_num: item.max_num,
      price:item.price,
      prices:item.prices,
      description:item.description,
      check_in:item.check_in,
      ikyu_id:item.ikyu_id,
      check_out:item.check_out,
      payment_method:item.payment_method,
      icon:item.icon,
      notice_mail : item.notice_mail
    }
    )
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.PLAN_LIST_LOADED:
      setList(action.list);
      PlanStore.emitChange()
    break;
    case Constants.PLAN_DETAIL_LOADED:
        setItem(action.item);
        PlanStore.emitChange()
    break;
    case Constants.CSV_SELECTED:
      setSelectedCSV(action.file);
      PlanStore.emitChange(FORM_CHANGE)
    break;

    case Constants.PLAN_PRICES_LOADED:
      setPrices(action.list);
      PlanStore.emitChange()
    break;

    case Constants.PLAN_UPDATE:
      update(action.name,action.value)
      PlanStore.emitChange()
    break;
    case Constants.PLAN_CREATED:
      PlanStore.emitChange(CREATED)
    break;
    case Constants.PLAN_DELETED:
      PlanStore.emitChange(DELETED)
    break;
    case Constants.PLAN_SET:
      setItem(action.item)
      PlanStore.emitChange()
    break;
    case Constants.PLAN_GET_IKYU:
      setIkyu(action.data)
      PlanStore.emitChange(CHECK_LOADED)
    break
    case Constants.PLAN_CLEAR_IKYU:
      clearIkyu()
      PlanStore.emitChange(CHECK_LOADED)
    break
    default:
  }
})
export default PlanStore
