import { Record } from 'immutable'
import { addDays } from 'date-fns';

const Book = Record({
  id:0,
  accommodation_id: null,
  name:null,
  mail:null,
  start_date:new Date(),
  end_date:addDays(new Date(),1),
  person_number:null,
  days:1,
  address:null,
  tel:null,
  price: null,
  payment_limit_date: new Date(),
  mini_memo:null,
  memo:null,
  via:null,
  sync:null,
  created_at:null,
  delete_flg:null,
  status:null,
  payments:[],
  options:[],
  uid:null,
  room_name:null,
  plan_name:null,
  room_id:null,
  plan_id:null,
  price_sub:null,
  job:null
});
export default Book;
