import { useState } from 'react';
import MailAction from './MailAction';
import Mail from './Mail.tsx';
import ResponseData from '../../action/ResponseData';





type MailJson = { 
  id:string
  from_address:string 
};


function useMailState() {

  const [error, setError] = useState(null)
  const [mail_list, setMailList] = useState<Array<Mail> | null>(null)
  const [mail_list_updated_at, setMailListUpdatedAt] = useState<Date|null>(null)



  const loadListHandler = (data:ResponseData) => {
   
    if(data.data){
      const _mail_list:Array<MailJson> = data.data
     
      setMailList(_mail_list.map(_mail => {
        return (new Mail({id:_mail.id, from_address:_mail.from_address}))
      }))
      setMailListUpdatedAt(new Date())

    }
  }
  const errorHandler = (data:ResponseData) => {
    setError(data.error)
  }

  const getList = (token:string) => {
    MailAction.getList(token, loadListHandler, errorHandler)
  } 

  

  return { mail_list, mail_list_updated_at, mail_error:error,  getMailList:getList}

}

export default useMailState