import React, { useState, useEffect } from 'react'
import {  useParams} from 'react-router-dom';
import Menu from '../../parts/Menu';
import Header from '../../parts/Header';
import usePlanState from './usePlanState.tsx';
import useAuthState from '../../../../auth/useAuthState';
import DatePicker,{registerLocale} from "react-datepicker"
import ja from 'date-fns/locale/ja'
import { addDays,  differenceInCalendarDays, isBefore, format } from 'date-fns';
import Price from '../../../../accommodation/plan/price/Price.js';
import PriceSetting from '../../../../accommodation/plan/price/PriceSetting.tsx';
import PlanPriceItem from '../PlanPriceItem.js';
import useHolidayState from '../../../../holiday/useHolidayState.tsx';
import usePriceState from '../../../../accommodation/plan/price/usePriceState.tsx';
import CheckHoliday from '../../../../holiday/CheckHoliday.tsx';

function PlanPriceCreatorApp(props:PropsValue) {
  registerLocale('ja', ja)

  
  const { accommodation_id } = useParams();
  const { plan_id } = useParams();
  const {plan, getPlan} = usePlanState()
  const {token, getToken} = useAuthState()
  const {holidays,holidays_updated_at,getHolidays} = useHolidayState()
  const {price_created, createPrice} = usePriceState()
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)

  const [price_list, setPriceList] = useState(null)
  const [list_header, setListHeader] = useState(null)
  const [list, setList] = useState(null)
  const [price_setting, setPriceSetting] = useState(new PriceSetting({}))
  const [unit_price_input, setUnitPriceInput] = useState(null)
  const [base_price_visible, setBasePriceVisible] = useState(false)
  const [holiday_price_visible, setHolidayPriceVisible] = useState(false)

  const [btn, setBtn] = useState(false)
  
  useEffect(() => {
    
    getToken()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
   
    getPlan(token, accommodation_id, plan_id)
    getHolidays(token)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {

    if(holidays && holidays.length > 0){
      setHolidayPriceVisible(true)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[holidays_updated_at]);

  useEffect(() => {

    if(plan && plan.max_num){
      setListHeader(
        [<div className="date">日付/人数</div>].concat(
          new Array(plan.max_num).fill(0).map((p,index) => {
            return<div className="free">{index+1}人</div>
          })
        )
      )
      
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[plan]);


  useEffect(() => {
    if(start_date && end_date && isBefore(start_date, end_date)){
      updatePrice()
      setBasePriceVisible(true)
    }else{
      setBasePriceVisible(false)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[start_date, end_date]);

  useEffect(() => {

    if(price_setting && price_setting.base_price){
      updatePrice()
      setBtn(true)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[price_setting]);


  useEffect(() => {
   
    if(price_created){
      window.location.href = "/m/accommodation/" + accommodation_id + "/plan/" + plan_id + "/price"
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[price_created]);



  const updatePrice = () => {
    const duration = differenceInCalendarDays( end_date, start_date)
    const _prices = []
    const _lsit = new Array(duration).fill(0).map((d, day_index) => {
      const day = addDays(start_date,day_index)

      const is_holiday = CheckHoliday.isHoliday(day,holidays)
      const is_holiday_day_before = CheckHoliday.isHolidayBefore(day,holidays)
      const units = new Array(plan.max_num).fill(0).map((p,index) => {
          const price = new Price({tmp_id:day_index + "_" + index, target_date:day,unit:(index+1)}).setNewPrice(price_setting, day, index+1, is_holiday, is_holiday_day_before)
    
          _prices.push(price)
          //setPrices(prices.push(price))
         
          return <PlanPriceItem price={price} edit={false} setPrice={updateNewPrice}/>
      })
      
      const holiday_class = is_holiday ? "holiday" : null
      return(
        <div className={"mail-list-item week_" + day.getDay() + " " + holiday_class}>
          <div className="date with-week">{format(day, 'yyyy年MM月dd日',{locale: ja})}</div>
          {units}
        </div>
      )
    })
    
    setList(_lsit)
    setPriceList(_prices)
    setUnitPriceInput(
      new Array(plan.max_num).fill(0).map((p,index) => {
        return <div className="left ml-1" key={"price_input_" + index }>{index + 1} 人
          <input type="number" className="w-4" value={price_setting.unit_step[index]} onChange={(e) => setUnitPrice(index, e)}/> 
        </div>
      })
    )
  }

  const updateNewPrice = (e) => {

    const target_price_index = price_list.findIndex(item => item.tmp_id === e) 
    if(target_price_index && target_price_index !== -1){
      const _list = price_list
      _list[target_price_index] =  e
      setPriceList(_list)
    }
  }

  const changeNumberHandler = (e) => {
    const target = e.target 

    const tmp_price_setting = price_setting.setIn([target.name], Number(target.value))
    setPriceSetting(new PriceSetting(tmp_price_setting))

  }

  const setUnitPrice = (index,e) => {
    const target = e.target
    const new_array = price_setting.unit_step
    new_array[index] = Number(target.value)
    const tmp_price_setting = price_setting.setIn(["unit_step"], new_array).setIn(["updated_at"], new Date())

    setPriceSetting(new PriceSetting(tmp_price_setting))
  }

  const setWeekPrice = (index,e) => {
    const target = e.target
    const new_array = price_setting.week_step
  
    
    new_array[index] = Number(target.value)
    const tmp_price_setting = price_setting.setIn(["week_step"], new_array).setIn(["updated_at"], new Date())

    setPriceSetting(new PriceSetting(tmp_price_setting))
  }

  const setHolidayPrice = (index, e) => {
    const target = e.target
    const new_array = price_setting.holiday_step
    new_array[index] = Number(target.value)
    const tmp_price_setting = price_setting.setIn(["holiday_step"], new_array).setIn(["updated_at"], new Date())

    setPriceSetting(new PriceSetting(tmp_price_setting))
  }
  

  const create = () => {
    setBtn(false)

    createPrice(token, price_list, plan_id )
  }
  
  return(
    <div className="inner">
        <main className="main">
          <Menu selected="accommodation" accommodation_id={accommodation_id}/>
          <div className="main-content calendar">
            <Header accommodation_id = {accommodation_id} id="calendar"/>

            <div className="open-close-section">

              <section className={"pannel full "}>
                <div className="section-header">
                    <div className="title">プラン | {plan ? plan.name : null} | 金額登録 </div>
              
                </div>
                <div className="section-content list form fix price-settings">
                  <div className="list-item">
                    <div className="item-head">設定期間</div>
                    <div className="item-content">
                      <DatePicker showPopperArrow={false} locale="ja" placeholderText="開始日" dateFormat="yyyy-MM-dd" selected={start_date} onChange={(date) => setStartDate(date)}  />&nbsp;　〜　&nbsp;
                      <DatePicker showPopperArrow={false} locale="ja" placeholderText="終了日" dateFormat="yyyy-MM-dd" selected={end_date} onChange={(date) => setEndDate(date)}  />
                    </div>
                  </div>
                    {base_price_visible ?
                   <div className="list-item">
                    <div className="item-head">基本金額（1人）</div>
                    <div className="item-content">
                      <input type="number" value={price_setting.base_price}  name="base_price" onChange={changeNumberHandler}/>&nbsp;円

                    </div>
                  </div>
                  : null}

                    {base_price_visible ?
                   <div className="list-item">
                    <div className="item-head">人数追加金額</div>
                    <div className="item-content">
                      
                      {unit_price_input}
                    </div>
                  </div>
                  : null}

                  {base_price_visible ?
                   <div className="list-item">
                    <div className="item-head">曜日追加金額</div>
                    <div className="item-content">
                      <div className=" right" >月曜日<input type="number" className="w-4" onChange={(e) => setWeekPrice(1,e)}/> 円</div>
                      <div className=" right ml-1" >火曜日<input type="number" className="w-4" onChange={(e) => setWeekPrice(2,e)}/> </div>
                      <div className=" right ml-1" >水曜日<input type="number" className="w-4" onChange={(e) => setWeekPrice(3,e)}/> </div>
                      <div className=" right ml-1" >木曜日<input type="number" className="w-4" onChange={(e) => setWeekPrice(4,e)}/> </div>
                      <div className=" right ml-1" >金曜日<input type="number" className="w-4" onChange={(e) => setWeekPrice(5,e)}/> </div>
                      <div className=" right ml-1" >土曜日<input type="number" className="w-4" onChange={(e) => setWeekPrice(6,e)}/> </div>
                      <div className=" right ml-1" >日曜日<input type="number" className="w-4" onChange={(e) => setWeekPrice(0,e)}/> </div>
                     
                    </div>
                  </div>
                  : null}

                {holiday_price_visible ?
                   <div className="list-item">
                    <div className="item-head">祝日追加金額</div>
                    <div className="item-content">
                      <div className="left" >祝日前日<input type="number" className="w-6" onChange={(e) => setHolidayPrice(1,e)}/> </div>
                      <div className="left ml-1" >祝日<input type="number" className="w-6" onChange={(e) => setHolidayPrice(0,e)}/> </div>       
                    </div>
                  </div>
                  : null}

                </div>

                <div className="mail-list-item header">
                  {list_header}
                </div>
                <div className="scrollable-container ">
                  <div className={"scrollable-vertical price "}>{list}</div>
                </div>
                
                <div className="button-wrap">
                 
                  <div className="section-content  button">
                    {btn ? <button className="create-btn midium" onClick={create}>金額を登録</button> : <i className="fa fa-circle-o-notch fa-spin "></i>}
                  </div>
                </div>


              </section>
            </div>
          </div>

        </main>
    </div>
  )
}

export default PlanPriceCreatorApp