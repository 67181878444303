
import 'whatwg-fetch'

import Action from '../../../../actions/Action'

const PlanAction = {


  get(token, accommodation_id, plan_id,  callback, errorHandler){
    const path =  "/admin/accommodations/" + accommodation_id + "/plans/" + plan_id
    const header = {}
    const body = null
    Action.fetchAuthorizedJson( path, token, "GET", header, body,  callback, errorHandler)
  },





 
}

export default PlanAction
