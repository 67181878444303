
import { Record } from 'immutable'

type PlanProps = {
  id:string | null
  name:string | null
  start_at:Date | null
  end_at: Date | null
  max_num : number | null
};
const defaultValues: PlanProps = {
  id:null,
  name:null,
  start_at:null,
  end_at:null,
  max_num:null
};

class Plan extends Record(defaultValues){



 
}
export default Plan
