
import 'whatwg-fetch'

import Action from '../../action/Action';

const MailAction = {

  getList(token, callback, errorHandler){
    const path =  "/admin/mail/addresses"
    const header = {}
    const body = null
    Action.fetchAuthorizedJson( path, token, "GET", header, body,  callback, errorHandler)
  },

  


 
}

export default MailAction
