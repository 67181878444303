import Action from '../action/Action'

const HolidayAction = {


  get(token,  callback, errorHandler){
    const path =  "/holidays"
    const header = {}
    const body = null
    Action.fetchAuthorizedJson( path, token, "GET", header, body,  callback, errorHandler)
  },


 
}

export default HolidayAction
