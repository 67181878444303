
import 'whatwg-fetch'

import Action from "../../../action/Action"

const PriceAction = {

  getList(token, callback, errorHandler){
    const path =  "/admin/mail/addresses"
    const header = {}
    const body = null
    Action.fetchAuthorizedJson( path, token, "GET", header, body,  callback, errorHandler)
  },


  update(token, plan_id, id, price, callback, errorHandler){
    const path =  "/admin/plans/" + plan_id + "/schedules/" + id 
    const header = {"content-type":"application/json"}
    const body =JSON.stringify({
      price:price,
    })
    Action.fetchAuthorizedJson( path, token, "PATCH", header, body,  callback, errorHandler)
  },
  
  create(token, items, plan_id,  callback, errorHandler){
    const path =  "/admin/plans/" + plan_id + "/schedules"
    const header = {"content-type":"application/json"}
    const body =JSON.stringify({
      prices:items,
    })
    Action.fetchAuthorizedJson( path, token, "POST", header, body,  callback, errorHandler)
  },


 
}

export default PriceAction
