

import { subDays,  isSameDay } from 'date-fns'
import Holiday from "./Holiday";

const CheckHoliday = {
  
  
  isHoliday: function(target_date:Date, list:Array<Holiday>) {

    const result = list.find(holliday => holliday.target_date && isSameDay(holliday.target_date, target_date))
    if(result) return true 
    return false
  },
  isHolidayBefore: function(target_date:Date, list:Array<Holiday>) {

    const result = list.find(holliday => holliday.target_date && isSameDay(subDays(holliday.target_date,1), target_date))
    if(result) return true 
    return false
  },
}

export default CheckHoliday
